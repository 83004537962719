import {Badge, Breadcrumb, Dropdown, Layout, Menu} from 'antd';
import {AuthContext} from 'context/Auth.context';
import {NotificationsContext} from 'context/Notifications.context';
import {MenuItem, SettingsContext} from 'context/Settings.context';
import {Bell, CaretDown, ChatsTeardrop, Gear, Question, User} from 'phosphor-react';
import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';
import SERVICES from 'services';
import './Header.less';
import {useSocket} from 'context/Socket.context';

const {Header} = Layout;
const NOTIFICATION_PAGE_ID = 8;
const STATISTICS_PAGE_ID = 7;
const SETTINGS_PAGE_ID = 9;
const GUIDES_PAGE_ID = 63;
const MAIN_PAGE_ID = 1;
const PROFILE_PAGE_ID = 43;
const ADMISSION_NOTIFICATIONS_PAGE_ID = 18;
const SUBJECTS_PAGE_ID = 26;

const LANGUAGES = {
	hy: 'ՀԱՅ',
	en: 'EN',
	ru: 'РУС'
};

const AppHeader = () => {
	const {socket} = useSocket();
	const navigate = useNavigate();
	const location = useLocation();
	const {t, i18n} = useTranslation();
	const {state: notificationsState, dispatch: notificationsDispatch} = useContext(NotificationsContext);
	const pathSnippets = location.pathname.split('/').filter(i => i);
	const {state: settingsState} = useContext(SettingsContext);
	const {state: authState, dispatch: authDispatch} = useContext(AuthContext);
	const [notificationCount, setNotificationCount] = useState(0);
	const choosenPage = location.pathname;

	const handleLogout = () => {
		authDispatch({
			type: 'LOGOUT'
		});
	};

	const settingsPage = settingsState.menuItems.find((el: any) => el.pageId === SETTINGS_PAGE_ID);
	const guidesPage = settingsState.menuItems.find((el: any) => el.pageId === GUIDES_PAGE_ID);
	const mainPage = settingsState.menuItems.find((el: any) => el.pageId === MAIN_PAGE_ID);
	const profilePage = settingsState.menuItems.find((el: any) => el.pageId === PROFILE_PAGE_ID);

	const getMenuItems = (routes: MenuItem[], defPath = '', defNameMap = {}) => {
		const breadcrumbNameMap: any = defNameMap;

		routes
			.filter(
				el =>
					el.pageId !== ADMISSION_NOTIFICATIONS_PAGE_ID &&
					el.pageId !== SETTINGS_PAGE_ID &&
					el.pageId !== NOTIFICATION_PAGE_ID &&
					el.pageId !== STATISTICS_PAGE_ID &&
					el.pageId !== PROFILE_PAGE_ID &&
					el.pageId !== SUBJECTS_PAGE_ID
			)
			.map(item => {
				let path = defPath + item.url.slice(item.url.lastIndexOf('/'));
				breadcrumbNameMap[path] = item.page;

				if (item.children && item.children.length) {
					// const submenuPath = path + item.children[0].url.slice(item.children[0].url.lastIndexOf('/'));
					// getMenuItems(item.children, path, breadcrumbNameMap);
					getMenuItems(item.children, path, breadcrumbNameMap);
				}
			});

		const extraBreadcrumbItems = pathSnippets.map((_, index) => {
			const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
			return (
				<Breadcrumb.Item key={url}>
					<span>{breadcrumbNameMap[url]}</span>
				</Breadcrumb.Item>
			);
		});

		const breadcrumbItems: any = [
			<Breadcrumb.Item key="/home">
				<span>{mainPage?.page}</span>
			</Breadcrumb.Item>
		].concat(extraBreadcrumbItems);

		return breadcrumbItems;
	};

	const breadcrumbItems = useMemo(
		() =>
			getMenuItems(settingsState.menuItems, '', {
				'/profile': profilePage?.page,
				'/settings': settingsPage?.page
			}),
		[location.pathname, settingsState.menuItems, settingsPage, profilePage]
	);

	const getNotification = useCallback(() => {
		SERVICES.NOTIFICATIONS.getNotifications().then(res => {
			const payload = {
				answeredAssignmentsWithoutMark: res.data.answeredAssignmentsWithoutMark,
				changedAssignmentsWithoutMark: res.data.changedAssignmentsWithoutMark,
				studentsWithoutFlowCount: res.data.studentsWithoutFlow.length,
				studentsWithoutGroupCount: res.data.studentsWithoutGroup.length,
				unconfirmedExamMarksCount: res.data.unconfirmedExamMarks.length,
				groupsWithoutFlowCount: res.data.groupsWithoutFlow.length,
				groupsWithoutFlowIDs: res.data.groupsWithoutFlow
			};

			notificationsDispatch({
				type: 'GET_NOT_ATTACHED_STUDENTS',
				payload: payload
			});
		});
	}, []);

	useEffect(() => {
		localStorage.setItem('language', i18n.language);
	}, [i18n.language]);

	useEffect(() => {
		socket?.emit('notification:get', {});
		socket?.on('notification:send', (res: any) => {
			setNotificationCount(res);
		});
		return () => {
			socket?.off('notification:send');
		};
	}, [socket]);

	useEffect(() => {
		let interval: any = null;
		if (authState.isAuthenticated) {
			interval = setInterval(() => {
				getNotification();
			}, 60000);
			getNotification();
		}
		return () => clearInterval(interval);
	}, [location.pathname, authState.isAuthenticated]);

	return (
		<>
			<Header className="header">
				<Breadcrumb>{breadcrumbItems}</Breadcrumb>
				<div className="right-actions">
					<Dropdown
						trigger={['click']}
						placement="bottom"
						overlay={() => (
							<Menu className="lang-dropdown">
								<Menu.Item
									key="0"
									onClick={() => {
										i18n.changeLanguage('hy');
									}}>
									ՀԱՅ
								</Menu.Item>
								<Menu.Item
									key="1"
									onClick={() => {
										i18n.changeLanguage('en');
									}}>
									ENG
								</Menu.Item>
								<Menu.Item
									key="2"
									onClick={() => {
										i18n.changeLanguage('ru');
									}}>
									РУС
								</Menu.Item>
							</Menu>
						)}>
						<a className="lang-link" onClick={e => e.preventDefault()}>
							{LANGUAGES[i18n.language as 'hy' | 'ru' | 'en']}
						</a>
					</Dropdown>
					{notificationsState.studentsWithoutGroupCount ||
					notificationsState.changedAssignmentsWithoutMark?.length ||
					notificationsState.answeredAssignmentsWithoutMark?.length ||
					notificationsState.studentsWithoutFlowCount ||
					notificationsState.unconfirmedExamMarksCount ||
					notificationsState.groupsWithoutFlowCount ? (
						<Dropdown
							trigger={['click']}
							overlay={() => (
								<Menu className="notification-dropdown">
									{notificationsState.studentsWithoutGroupCount ? (
										<Menu.Item
											key="groupCount"
											onClick={() => {
												navigate('/students/main', {
													state: {notAttachedStudentsType: 1}
												});
											}}>
											<div>{t('studentsWithoutGroup')} - </div>
											<div>{notificationsState.studentsWithoutGroupCount}</div>
										</Menu.Item>
									) : null}

									{notificationsState.answeredAssignmentsWithoutMark?.length ? (
										<Menu.Item
											key="flowCount"
											onClick={() => {
												navigate('/assignments', {
													state: {
														answeredAssignmentsWithoutMark:
															notificationsState.answeredAssignmentsWithoutMark
													}
												});
											}}>
											<div>{t('answeredAssignmentsWithoutMark')} - </div>
											<div>
												{notificationsState.answeredAssignmentsWithoutMark?.length}
											</div>
										</Menu.Item>
									) : null}

									{notificationsState.changedAssignmentsWithoutMark?.length ? (
										<Menu.Item
											key="flowCount"
											onClick={() => {
												navigate('/assignments', {
													state: {
														changedAssignmentsWithoutMark:
															notificationsState.changedAssignmentsWithoutMark
													}
												});
											}}>
											<div>{t('changedAssignmentsWithoutMark')} - </div>
											<div>
												{notificationsState.changedAssignmentsWithoutMark?.length}
											</div>
										</Menu.Item>
									) : null}

									{notificationsState.studentsWithoutFlowCount ? (
										<Menu.Item
											key="flowCount"
											onClick={() => {
												navigate('/students/main', {
													state: {notAttachedStudentsType: 2}
												});
											}}>
											<div>{t('studentsWithoutFlow')} - </div>
											<div>{notificationsState.studentsWithoutFlowCount}</div>
										</Menu.Item>
									) : null}

									{notificationsState.unconfirmedExamMarksCount ? (
										<Menu.Item
											key="examMarkCount"
											onClick={() => {
												navigate('/exams/marks', {
													state: {unconfirmedExamMarks: true}
												});
											}}>
											<div>{t('examMarksConfirm')} - </div>
											<div>{notificationsState.unconfirmedExamMarksCount}</div>
										</Menu.Item>
									) : null}

									{notificationsState.groupsWithoutFlowCount ? (
										<Menu.Item
											key="examMarkCount"
											onClick={() => {
												navigate('/curriculum/groups', {
													state: {groupIDs: notificationsState.groupsWithoutFlowIDs}
												});
											}}>
											<div>{t('groupsWithoutFlow')} - </div>
											<div>{notificationsState.groupsWithoutFlowCount}</div>
										</Menu.Item>
									) : null}
								</Menu>
							)}>
							<Badge
								count={
									notificationsState.studentsWithoutGroupCount +
									notificationsState.studentsWithoutFlowCount +
									notificationsState.unconfirmedExamMarksCount +
									notificationsState.changedAssignmentsWithoutMark.length +
									notificationsState.answeredAssignmentsWithoutMark.length +
									notificationsState.groupsWithoutFlowCount
								}
								overflowCount={9999}
								style={{cursor: 'pointer'}}>
								<Bell weight="regular" className="notifications-bell" size={24} />
							</Badge>
						</Dropdown>
					) : (
						<Bell weight="regular" className="notifications-bell" size={24} />
					)}
					<div
						onClick={() => navigate('/chat', {state: {notAttachedStudentsType: 2}})}
						style={{position: 'relative', marginTop: 15}}>
						{notificationCount ? (
							<div className="notification-count">{notificationCount}</div>
						) : (
							<></>
						)}
						<ChatsTeardrop
							weight="regular"
							style={{cursor: 'pointer', marginLeft: '0px !important'}}
							className="notifications-bell"
							size={24}
						/>
					</div>
					{!!settingsPage?.get && (
						<Gear
							size={24}
							weight="regular"
							onClick={() =>
								navigate(settingsPage.url.slice(settingsPage.url.lastIndexOf('/')))
							}
							style={{cursor: 'pointer'}}
						/>
					)}
					{!!guidesPage?.get && (
						<Question
							size={26}
							weight="regular"
							onClick={() => navigate(guidesPage.url.slice(guidesPage.url.lastIndexOf('/')))}
							style={{cursor: 'pointer'}}
						/>
					)}
					<Dropdown
						trigger={['click']}
						overlay={() => (
							<Menu
								className="profile-dropdown"
								defaultSelectedKeys={[choosenPage]}
								selectedKeys={[choosenPage]}>
								{!!profilePage?.get && (
									<Menu.Item
										key={profilePage.url.slice(profilePage.url.lastIndexOf('/'))}
										onClick={() => {
											navigate(profilePage.url.slice(profilePage.url.lastIndexOf('/')));
										}}>
										{profilePage?.page}
									</Menu.Item>
								)}
								<Menu.Item key="2">
									<a onClick={handleLogout}>{t('logout')}</a>
								</Menu.Item>
							</Menu>
						)}>
						<a onClick={e => e.preventDefault()} className="profile-link">
							<User size={24} weight="regular" />
							<CaretDown style={{marginLeft: 10}} size={16} weight="fill" />
						</a>
					</Dropdown>
				</div>
			</Header>
		</>
	);
};

export default AppHeader;
