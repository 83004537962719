import axiosClient from 'utils/axiosClient';
import api from 'api';

const getAttendanceByWeekId = async (lessonWeekID: number, lessonsID: number, params: any) => {
	const response = await axiosClient.get(
		api.ATTENDANCE.MAIN.ATTENDANCE_BY_WEEK_ID + lessonWeekID + '/' + lessonsID,
		{params}
	);
	return response.data;
};

const updateAttendance = async (lessonWeekID: number, lessonsID: number, attendanceID: number, data: any) => {
	const response = await axiosClient.patch(
		api.ATTENDANCE.MAIN.ATTENDANCE_BY_WEEK_ID + lessonWeekID + '/' + lessonsID + '/' + attendanceID,
		data
	);
	return response.data;
};

const getAttendanceGranding = async (modelGroupID: number, params?: any) => {
	const response = await axiosClient.get(api.ATTENDANCE.MAIN.GRANDING + `${modelGroupID}`, {
		params
	});
	return response.data;
};

const getAttendanceSummary = async (attendanceID: number, params?: any) => {
	const response = await axiosClient.get(api.ATTENDANCE.MAIN.SUMMARY + `${attendanceID}/summary`, {
		params
	});
	return response.data;
};
const updateAttendanceSummary = async (data: any, attendanceID: number, id: number) => {
	const response = await axiosClient.patch(
		api.ATTENDANCE.MAIN.SUMMARY + `${attendanceID}/summary/${id}`,
		data
	);
	return response.data;
};

const updateAttendanceGranding = async (data: any, attendanceID: number | string, id: number) => {
	const response = await axiosClient.patch(api.ATTENDANCE.MAIN.GRANDING + `${attendanceID}/${id}`, data);
	return response.data;
};

const getAttendanceByGroups = async (params?: any) => {
	const response = await axiosClient.get(api.CURRICULUM.GROUPS.GROUP_CURRICULUM, {
		params
	});
	return response.data;
};

const getAttendanceByGroupsAndID = async (id: number, params?: any) => {
	const {groupID, ...other} = params;
	const response = await axiosClient.get(api.CURRICULUM.GROUPS.GROUP_CURRICULUM + `/${groupID}/${id}`, {
		params: other
	});
	return response.data;
};

const service = {
	getAttendanceByWeekId,
	updateAttendance,
	getAttendanceGranding,
	getAttendanceSummary,
	updateAttendanceSummary,
	updateAttendanceGranding,
	getAttendanceByGroups,
	getAttendanceByGroupsAndID
};

export default service;
